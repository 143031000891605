<template>
  <div class="site-creation" v-scrollanimation>
    <div class="container">
      <div class="site-creation__inner flex f-space-between">
        <h2 class="site-creation__title headline-1 w700 left-to-right">
          Что входит в создание сайта?
        </h2>
        <div class="site-creation__wrap">
          <div
            class="site-creation__item bottom-to-top"
            v-for="(item, index) in creations"
            :key="index"
            :class="`delay-${index + 2 * index}`"
          >
            <h3 class="site-creation__num headline-3 w700">{{ item.num }}</h3>
            <p class="site-creation__text caption-3 w400">
              {{ item.text }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const creations = [
  {
    num: "01",
    text: "Продуманный и функциональный прототип",
  },
  {
    num: "02",
    text: "Красивый продающий дизайн",
  },
  {
    num: "03",
    text: "Адаптация под любые устройства",
  },
  {
    num: "04",
    text: "Правильная техническая структура и грамотная верстка",
  },
  {
    num: "05",
    text: "Консультация специалистов",
  },
  {
    num: "06",
    text: "Предварительное тестирование",
  },
];
</script>
<style lang="scss" scoped>
.site-creation {
  position: relative;

  &__wrap {
    max-width: 34rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2.5rem;
    grid-row-gap: 2.75rem;
  }

  &__inner {
    padding: 6.25rem 0;
  }

  &__title {
    max-width: 30rem;
  }

  &__num {
    color: var(--secondary-1);
    margin-bottom: 0.5rem;
  }

  &__text {
    color: var(--neutrals-8);
  }
}

@media screen and (max-width: 1336px) {
  .site-creation {
    &__inner {
      padding: 5rem 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .site-creation {
    &__inner {
      flex-direction: column;
      padding: 3rem 0;
    }

    &__title {
      margin-bottom: 2rem;
      font-size: 2.5rem;
    }

    &__wrap {
      font-size: 15px;
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 425px) {
  .site-creation {
    &__wrap {
      font-size: 12px;
    }
  }
}
</style>
