<template>
  <div class="site-types" v-scrollanimation>
    <div class="container">
      <div class="site-types__inner">
        <h2 class="site-types__title headline-2 w700 left-to-right">
          Типы сайтов
        </h2>
        <div class="site-types__wrap flex f-space-between">
          <div
            class="site-types__item bottom-to-top"
            v-for="(item, index) in siteTypes"
            :key="index"
            :class="`delay-${index + 3 * index}`"
          >
            <img
              :src="
                require(`@/assets/images/site-types/type_poster_${
                  index + 1
                }.png`)
              "
              :alt="item.title"
            />
            <h3 class="site-types__type headline-3 w700">{{ item.title }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const siteTypes = [
  {
    title: "Интернет-магазин",
  },
  {
    title: "Landing Page",
  },
  {
    title: "Корпоративные сайты",
  },
  {
    title: "Техническая поддержка",
  },
];
</script>
<style lang="scss" scoped>
.site-types {
  &__inner {
    padding: 6.25rem 0;
  }

  &__title {
    margin-bottom: 4.06rem;
  }

  &__wrap {
    grid-gap: 2.5rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  &__item {
    width: 100%;

    img {
      width: 100%;
      height: 20rem;
      border-radius: 1.25rem;
      margin-bottom: 1.5rem;
    }
  }
}

@media screen and (max-width: 1336px) {
  .site-types {
    &__inner {
      padding: 5rem 0;
    }

    &__item {
      img {
        height: 16rem;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .site-types {
    &__title {
      margin-bottom: 2rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .site-types {
    &__wrap {
      overflow: hidden;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__item {
      width: 13rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .site-types {
    &__inner {
      padding: 3rem 0;
    }
  }
}
</style>
