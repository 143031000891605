<template>
  <div class="whyus" v-scrollanimation>
    <div class="container">
      <div class="whyus__inner">
        <h2 class="whyus__title headline-2 w700 left-to-right">Почему мы?</h2>
        <div class="whyus__wrap flex f-space-between">
          <div class="whyus__item bottom-to-top delay-3">
            <div class="whyus__icon flex f-center">
              <svg
                width="2rem"
                height="2rem"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.4935 8L8.72914 18.9332L4.47387 14.5999L2.63281 16.4667L8.72914 22.6667L21.3346 9.86675L19.4935 8ZM14.2232 20.1258L16.7291 22.6667L29.3346 9.86675L27.4935 8L16.7291 18.9332L16.0629 18.2577L14.2232 20.1258Z"
                  fill="#9757D7"
                />
              </svg>
            </div>
            <h3 class="headline-3 w700 bottom-to-top delay-4">01</h3>
            <p class="caption-3 w400 bottom-to-top delay-5">
              Более 50 выполненых проектов
            </p>
          </div>
          <div class="whyus__item bottom-to-top delay-6">
            <div class="whyus__icon flex f-center">
              <svg
                width="2rem"
                height="2rem"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.9987 30.6673C7.89852 30.6673 1.33203 24.1008 1.33203 16.0007C1.33203 7.90047 7.89852 1.33398 15.9987 1.33398C24.0989 1.33398 30.6654 7.90047 30.6654 16.0007C30.6654 24.1008 24.0989 30.6673 15.9987 30.6673ZM25.8647 22.8335C27.2102 20.8944 27.9987 18.5396 27.9987 16.0007C27.9987 9.37323 22.6261 4.00065 15.9987 4.00065C9.37128 4.00065 3.9987 9.37323 3.9987 16.0007C3.9987 18.5396 4.78721 20.8944 6.1327 22.8335C7.6333 20.8514 11.1595 20.0007 15.9987 20.0007C20.8379 20.0007 24.3641 20.8514 25.8647 22.8335ZM23.9516 24.987C23.5614 23.5838 20.7579 22.6673 15.9987 22.6673C11.2395 22.6673 8.43604 23.5838 8.04579 24.987C10.1631 26.8622 12.948 28.0007 15.9987 28.0007C19.0494 28.0007 21.8343 26.8622 23.9516 24.987ZM15.9987 20.0007C13.0132 20.0007 10.6654 17.9038 10.6654 13.334C10.6654 10.3417 12.7708 8.00065 15.9987 8.00065C19.2176 8.00065 21.332 10.5628 21.332 13.6007C21.332 17.9735 18.9561 20.0007 15.9987 20.0007ZM13.332 13.334C13.332 16.3597 14.423 17.334 15.9987 17.334C17.569 17.334 18.6654 16.3985 18.6654 13.6007C18.6654 11.9345 17.6196 10.6673 15.9987 10.6673C14.3103 10.6673 13.332 11.7551 13.332 13.334Z"
                  fill="#9757D7"
                />
              </svg>
            </div>
            <h3 class="headline-3 w700 bottom-to-top delay-7">02</h3>
            <p class="caption-3 w400 bottom-to-top delay-8">
              Наши клиенты всегда довольны
            </p>
          </div>
          <div class="whyus__item bottom-to-top delay-9">
            <div class="whyus__icon flex f-center">
              <svg
                width="2rem"
                height="2rem"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M25.332 5.33398H6.66536C5.19261 5.33398 3.9987 6.52789 3.9987 8.00065V20.0007H1.33203V24.0007C1.33203 25.4734 2.52594 26.6673 3.9987 26.6673H27.9987C29.4715 26.6673 30.6654 25.4734 30.6654 24.0007V20.0007H27.9987V8.00065C27.9987 6.52789 26.8048 5.33398 25.332 5.33398ZM25.332 20.0007H6.66536V8.00065H25.332V20.0007ZM3.9987 24.0007V22.6673H27.9987V24.0007H3.9987Z"
                  fill="#9757D7"
                />
              </svg>
            </div>
            <h3 class="headline-3 w700 bottom-to-top delay-10">03</h3>
            <p class="caption-3 w400 bottom-to-top delay-11">
              Команда опытных специалистов
            </p>
          </div>
          <div class="whyus__item bottom-to-top delay-12">
            <div class="whyus__icon flex f-center">
              <svg
                width="2rem"
                height="2rem"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.117 23.9993L8.0013 29.0717V23.9993H5.33464C3.86188 23.9993 2.66797 22.8054 2.66797 21.3327V5.33268C2.66797 3.85992 3.86188 2.66602 5.33464 2.66602H26.668C28.1407 2.66602 29.3346 3.85992 29.3346 5.33268V21.3327C29.3346 22.8054 28.1407 23.9993 26.668 23.9993H16.117ZM10.668 24.2604L15.3522 21.3327H26.668V5.33268H5.33464V21.3327H10.668V24.2604ZM20.3918 8.38987L14.668 14.1137L11.6108 11.0565L9.72516 12.9422L14.668 17.885L22.2774 10.2755L20.3918 8.38987Z"
                  fill="#9757D7"
                />
              </svg>
            </div>
            <h3 class="headline-3 w700l bottom-to-top delay-13">04</h3>
            <p class="caption-3 w400 bottom-to-top delay-14">
              Индивидуальный подход к каждому клиенту
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style lang="scss" scoped>
.whyus {
  &__inner {
    padding: 6.25rem 0;
  }

  &__title {
    margin-bottom: 4.38rem;
  }

  &__icon {
    width: 3.75rem;
    height: 3.75rem;

    border: 0.06rem solid #c9c9c9;
    border-radius: 1rem;
    margin-bottom: 1.5rem;
  }

  &__wrap {
    gap: 2.5rem;
  }

  &__item {
    flex: 1;

    h3 {
      margin-bottom: 0.5rem;
    }

    p {
      padding-right: 0.63rem;
    }
  }
}

@media screen and (max-width: 1336px) {
  .whyus {
    &__inner {
      padding: 5rem 0;
    }
  }
}

@media screen and (max-width: 992px) {
  .whyus {
    &__title {
      margin-bottom: 3rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .whyus {
    &__inner {
      padding: 3rem 0;
    }

    &__wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 3rem;
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 375px) {
  .whyus {
    &__wrap {
      font-size: 12px;
    }
  }
}
</style>
